<template>
  <v-card class="mb-0 pb-0 mt-0 pt-0 mb-0 pb-2">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ current.adress }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ current.time }} saat: {{ current.hour }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      <v-row align="center">
        <v-col class="text-h3 mb-0 pb-0" cols="6">
          {{ current.temp }}&deg;C
        </v-col>
        <v-col cols="6">
          <v-img :src="current.weatherIcon" alt="Sunny image" width="60" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-list-item dense>
      <v-list-item-icon>
        <v-icon>mdi-send</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ current.wind_speed }} km/h</v-list-item-subtitle>

      <v-list-item-icon>
        <v-icon>mdi-cloud-download</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle class="ml-2">
        {{ current.humidity }} %
      </v-list-item-subtitle>
    </v-list-item>
    <v-divider />
    <v-data-table
      dense
      :headers="weater_headers"
      :items="dailyWeather"
      hide-default-header
      hide-default-footer
    >
      <template v-slot:item.icon="{ item }">
        <v-img :src="item.icon" alt="Sunny image" width="40" />
      </template>

      <template v-slot:item.temp="{ item }">
        <span v-html="item.temp" /> &deg;C
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import i18n from "@/locale";
import moment from "moment";
import request from "@/api/utils";

export default {
  data: () => ({
    current: {},
    weatherIcon: "",
    dailyWeather: [],
    weater_headers: [
      { text: "day", value: "day" },
      { text: "icon", value: "icon" },
      { text: "temp", value: "temp" },
    ],
  }),
  created() {},
  mounted() {
    const self = this;
    setTimeout(() => {
      self.weatherfunc();
    }, 2000);

    setInterval(this.weatherfunc, 300000);
  },
  methods: {
    weatherfunc() {
      const formdata = {
        type: "hourly",
      };

      const url = "/api/weater/hourly";
      const weather1 = request.Api(url, formdata, "post");
      weather1.then((res) => {
        if (res.data.success === "false") {
          return;
        }
        if (res.data.data.length === 0) {
          return;
        }
        const temp_description = res.data.data[0].weather[0].description;
        const { date } = res.data.data[0];
        const hour = parseInt(res.data.data[0].Hour);
        let hh = "";
        hour < 10 ? (hh = `0${hour}:00`) : (hh = `${hour}:00`);

        const sunrise_s = res.data.data[0].sunrise;
        const sunset_s = res.data.data[0].sunset;

        const sunrise = moment(sunrise_s * 1000).format("YYYY-MM-DD HH:mm:ss");
        const sunset = moment(sunset_s * 1000).format("YYYY-MM-DD HH:mm:ss");
        let type = "day";
        if (date < sunrise) {
          type = "night";
        }
        if (date > sunset) {
          type = "night";
        }

        let w_icon = this.weaterIcons(type, temp_description);

        this.current = {
          temp: parseInt(res.data.data[0].temp),
          time: res.data.data[0].Time,
          hour: hh,
          wind_speed: res.data.data[0].wind_speed,
          humidity: res.data.data[0].humidity,
          adress: res.data.data[0].adress,
          weatherIcon: w_icon,
        };

        w_icon = this.weaterIcons(
          type,
          res.data.data[1].weather[0].description
        );

        this.dailyWeather[0] = {
          day: moment(res.data.data[1].date).format("DD.MM.YYYY HH:00"),
          icon: w_icon,
          temp: parseInt(res.data.data[1].temp),
        };
      });

      const formdata1 = {
        type: "daily",
      };
      const url1 = "/api/weater/daily";
      const daily = [
        {
          day: "",
          icon: "",
          temp: "",
        },
      ];

      this.dailyWeather = daily;
      const weather2 = request.Api(url1, formdata1, "post");

      weather2.then((res) => {
        this.dailyWeather.push(this.weaterFun(res.data.data[0]));
        this.dailyWeather.push(this.weaterFun(res.data.data[1]));
        this.dailyWeather.push(this.weaterFun(res.data.data[2]));
      });
    },

    weaterFun(result) {
      if (result === undefined) {
        return;
      }
      if (result.weather.length == 0) {
        return;
      }
      const temp_description = result.weather[0].description;
      const w_icon = this.weaterIcons("day", temp_description);
      const days = i18n.t(`daily.${moment(result.Time).format("dddd")}`);

      return {
        day: days,
        icon: w_icon,
        temp: parseInt(result.temp.day),
      };
    },

    weaterIcons(type, temp_description) {
      let w_icon = "";

      const tmp_1 = temp_description.split(" ");
      if (tmp_1.indexOf("clear") > -1) {
        w_icon = `${type}_clear_sky`;
      }

      if (tmp_1.indexOf("clouds") > -1) {
        w_icon = `${type}_clouds`;
      }
      if (tmp_1.indexOf("rain") > -1) {
        w_icon = `${type}_rain`;
      }

      if (tmp_1.indexOf("snow") > -1) {
        w_icon = `${type}_snow`;
      }
      const url = window.location.href;
      if (url.indexOf("localhost") > -1) {
        return `img/${w_icon}.svg`;
      }

      return `/public/img/${w_icon}.svg`;
    },
  },
};
</script>

<style scoped>
</style>
