/* eslint-disable no-undef */
/* eslint-disable no-const-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import i18n from '@/locale';

import {
  getSantrallerMinuteGrup,
} from '@/api/Request/santraller';
import {
  getSantralDaily,
  getSantralMonthly,
  getSantralYearly,
  getSantralTotal,
  getSantraLastData,
} from '@/api/Request/santral';
import {
  getSantrallerDaily,
  getSantrallerMonthly,
  getSantrallerYearly,
  getSantrallerTotal,
} from '@/api/Request/farm';
import {
  list,
} from '@/api/Request/sensor';
import chartOptions from '@/api/chart/chart_options';
import request from '@/api/utils';
import helper from '@/api/helper';
import setDate from '@/api/component/date';
import icon from '@/api/component/common';

const get_SantralLiveData = (santralData, sensorboxData, measureList) => new Promise((resolve) => {
  Promise.all([santralData, sensorboxData]).then((values) => {
    let liveData = {
      Irr: 0,
      Temp: 0,
      IstantaneousPower: 0,
      PerformanceRatio: 0,
    };
    if (values[0].data.success === 'true' && values[0].data !== undefined) {
      liveData = {
        ...liveData,
        ...values[0].data.data,
      };
    }
    if (values[1].data.success === 'true' && values[1].data.data !== null) {
      let Irr = 0;
      let Temp = 0;

      let indexirr = 0;
      let indextemp = 0;
      Object.keys(values[1].data.data).forEach((item) => {
        Object.keys(values[1].data.data[item]).forEach((i) => {
          if (i.indexOf('Irr') > -1) {
            const tmp = values[1].data.data[item][i];
            if (tmp > 0) {
              Irr += tmp;
              // eslint-disable-next-line no-plusplus
              indexirr++;
            }
          }
          if (i.indexOf('Temp') > -1) {
            const tmp = values[1].data.data[item][i];

            if (tmp > 0) {
              Temp += tmp;
              // eslint-disable-next-line no-plusplus
              indextemp++;
            }
          }
        });
      });

      if (indexirr === 0) {
        indexirr = 1;
      }
      if (indextemp === 0) {
        indextemp = 1;
      }
      const sensorObj = {
        Irr: 0,
        Temp: 0,
      };
      sensorObj.Irr = Irr / indexirr;
      sensorObj.Temp = Temp / indextemp;

      liveData = {
        ...liveData,
        ...sensorObj,
      };
    }

    let chartOption = {

    };
    if (values[0].data.data) {
      chartOption = {
        updateTime: values[0].data.data.date,
      };
    }

    let icons = '';
    measureList.forEach((item) => {
      icons = icon.measures_icon(item.value);
      const max = icon.measures_max(item.value);

      const tmpData = [{
        value: parseFloat(liveData[item.value]).toFixed(2),
        name: item.text,
        title: {
          offsetCenter: [0, '95%'],
        },
        detail: {
          offsetCenter: [0, '80%'],
        },
      }];

      const option = chartOptions.gauge_options(tmpData, max, icons);
      chartOption[item.value] = option;
    });

    resolve(chartOption);
  });
});

const getSantralLiveData = (formdata, measureList) => new Promise((resolve) => {
  const santralData = getSantraLastData(formdata.santral);
  const sensorboxData = list(formdata.sensor);

  Promise.all([santralData, sensorboxData]).then((values) => {
    let liveData = {
      Irr: 0,
      Temp: 0,
      IstantaneousPower: 0,
      PerformanceRatio: 0,
    };

    if (values[0].data.success === 'true' && values[0].data !== undefined) {
      liveData = {
        ...liveData,
        ...values[0].data.data[0],
      };
    }
    if (values[1].data.success === 'true' && values[1].data.data !== null) {
      const tempVal = {
        Irr: 0,
        Temp: 0,
      };

      let irrindex1 = 0;
      let tempindex1 = 0;
      /* const {
        sensor,
      } = formdata.sensor.condiniton; */

      values[1].data.data.forEach((item) => {
        const irrKey = 'Irradiation';
        const irrValue = 'Irr';

        const tempKey = 'PanelTemp';
        const tempValue = 'Temp';

        if (item[irrKey] > 0) {
          tempVal[irrValue] += item[irrKey];
          irrindex1 += 1;
        }

        if (item[tempKey] > 0) {
          tempVal[tempValue] += item[tempKey];
          tempindex1 += 1;
        }
      });

      if (tempVal.Irr > 0) {
        // eslint-disable-next-line radix
        tempVal.Irr = parseInt(tempVal.Irr / irrindex1);
      }
      if (tempVal.Temp > 0) {
        // eslint-disable-next-line radix
        tempVal.Temp = parseInt(tempVal.Temp / tempindex1);
      }

      liveData = {
        ...liveData,
        ...tempVal,
      };
    }

    const chartOption = {};
    if (values[0].data.success === 'true') {
      if (values[0].data.data[0]) {
        chartOption.updateTime = values[0].data.data[0].date;
      }
    }

    measureList.forEach((item) => {
      const max = icon.measures_max(item.value);
      const tmpData = [{
        value: parseFloat(liveData[item.value]).toFixed(2),
        name: i18n.t(item.value),
        title: {
          offsetCenter: [0, '70%'],
        },
        detail: {
          offsetCenter: [0, '90%'],
        },
      }];

      const option = chartOptions.gauge_options(tmpData, max, item.unit);
      chartOption[item.value] = option;
    });

    resolve(chartOption);
  });
});

const setdata = (measures, date, res) => {
  const obj = {};
  measures.forEach((measure) => {
    obj[measure] = 0;
  });
  obj.date = date;
  if (res.data.success === 'true') {
    if (!helper.is_Empty(res.data.data[0])) {
      const temp = {
        ...setMeasures(res.data.data[0]),
      };
      return {
        ...obj,
        ...temp,
      };
    }
  }

  return obj;
};

const setdata1 = (measures, date, res) => {
  const obj = {};

  measures.forEach((measure) => {
    obj[measure] = 0;
  });
  obj.date = date;

  if (!helper.is_Empty(res.data.data)) {
    const temp = {
      ...setMeasures(res.data.data),
    };
    return {
      ...obj,
      ...temp,
    };
  }

  return obj;
};
const getSantralStatsData = (measures = [], selectedsantral = 'null') => new Promise((resolve) => {
  const startDate = moment().format('YYYY-MM-DD');
  const dailyformData = {
    condiniton: {
      measures,
      startDate,
      finishDate: startDate,
    },
  };
  if (selectedsantral !== 'null') {
    dailyformData.condiniton.santral = selectedsantral;
  }
  const tmpData = [];
  const promise = [];
  const d = getSantralDaily(dailyformData);
  promise.push(d);

  const year = moment().format('YYYY');
  // eslint-disable-next-line radix
  const month = parseInt(moment().format('MM'));
  const monthlyformdata = {
    condiniton: {
      measures,
      date: [{
        year,
        month: [month],
      }],
    },
  };
  if (selectedsantral !== 'null') {
    monthlyformdata.condiniton.santral = selectedsantral;
  }

  const m = getSantralMonthly(monthlyformdata);
  promise.push(m);

  const yearlyformdata = {
    condiniton: {
      measures,
      date: [year],
    },
  };
  if (selectedsantral !== 'null') {
    yearlyformdata.condiniton.santral = selectedsantral;
  }

  const y = getSantralYearly(yearlyformdata);
  promise.push(y);

  const totalformdata = {
    condiniton: {
      measures,
    },
  };
  if (selectedsantral !== 'null') {
    totalformdata.condiniton.santral = selectedsantral;
  }
  const t = getSantralTotal(totalformdata);
  promise.push(t);

  Promise.all(promise).then((item) => {
    const date1 = moment().format('DD-MM-YYYY');
    const temp1 = setdata(measures, date1, item[0]);

    tmpData.push(temp1);
    // eslint-disable-next-line radix, no-shadow
    const m = parseInt(moment().format('MM'));
    const date2 = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;

    const temp2 = setdata(measures, date2, item[1]);
    tmpData.push(temp2);

    const temp3 = setdata(measures, year, item[2]);
    tmpData.push(temp3);

    /* const temp4 = setdata(measures, 'Toplam', item[3]);
    tmpData.push(temp4); */
  });

  resolve(tmpData);
});

const getSantrallerStatsData = (measures = []) => new Promise((resolve) => {
  const startDate = moment().format('YYYY-MM-DD');
 
  const dailyformData = {
    condiniton: {
      measures,
      startDate,
      finishDate: startDate,
    },
  };

  const tmpData = [];
  const promise = [];
  const d = getSantrallerDaily(dailyformData);
  promise.push(d);

  const year = moment().format('YYYY');
  // eslint-disable-next-line radix
  const month = parseInt(moment().format('MM'));
  const monthlyformdata = {
    condiniton: {
      measures,
      date: [{
        year,
        month: [month],
      }],
    },
  };

  const m = getSantrallerMonthly(monthlyformdata);
  promise.push(m);

  const yearlyformdata = {
    condiniton: {
      measures,
      date: [year],
    },
  };

  const y = getSantrallerYearly(yearlyformdata);
  promise.push(y);

  const totalformdata = {
    condiniton: {
      measures,
    },
  };

  const t = getSantrallerTotal(totalformdata);
  promise.push(t);

  Promise.all(promise).then((item) => {
    const date1 = moment().format('DD-MM-YYYY');
   
    const temp1 = setdata1(measures, date1, item[0]);
    tmpData.push(temp1);
    // eslint-disable-next-line radix, no-shadow
    const m = parseInt(moment().format('MM'));
    const date2 = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;

    const temp2 = setdata1(measures, date2, item[1]);
    tmpData.push(temp2);

    const temp3 = setdata1(measures, year, item[2]);
    tmpData.push(temp3);

   /*  const temp4 = setdata1(measures, 'Toplam', item[3]);
    tmpData.push(temp4); */
  });

  resolve(tmpData);
});

const getSantralTotalTableData = (measures, selectedsantral) => new Promise((resolve) => {
  // eslint-disable-next-line camelcase
  let s_url = 'santraller';
  if (selectedsantral !== 'null' && selectedsantral !== undefined) {
    // eslint-disable-next-line camelcase
    s_url = 'santral';
  }

  // ----------daily--------------------------------
  const promise = [];
  const startDate = moment().format('YYYY-MM-DD');

  // eslint-disable-next-line camelcase
  const d_url = `/api/${s_url}/dailypower`;

  // eslint-disable-next-line camelcase
  const d_formdata = {
    condiniton: {
      measures,
      startDate,
      finishDate: startDate,
    },
  };
  if (selectedsantral !== 'null') {
    d_formdata.condiniton.santral = selectedsantral;
  }

  const dailyData = request.Api(d_url, d_formdata, 'post');
  promise.push(dailyData);

  // ----------month--------------------------------
  const year = moment().format('YYYY');
  // eslint-disable-next-line radix
  const month = parseInt(moment().format('MM'));

  // eslint-disable-next-line camelcase
  const m_formdata = {
    condiniton: {
      measures,
      date: [{
        year,
        month: [month],
      }],
    },
  };
  if (selectedsantral !== 'null') {
    m_formdata.condiniton.santral = selectedsantral;
  }

  // eslint-disable-next-line camelcase
  const url1 = `/api/${s_url}/monthlypower`;

  const monthData = request.Api(url1, m_formdata, 'post');
  promise.push(monthData);

  // ----------year--------------------------------

  // eslint-disable-next-line camelcase
  const url2 = `/api/${s_url}/yearlypower`;

  // eslint-disable-next-line camelcase
  const y_formdata = {
    condiniton: {
      measures,
      date: [year],
    },
  };
  if (selectedsantral !== 'null') {
    y_formdata.condiniton.santral = selectedsantral;
  }
  const yearData = request.Api(url2, y_formdata, 'post');
  promise.push(yearData);

  // ----------total--------------------------------

  // eslint-disable-next-line camelcase
  const url3 = `/api/${s_url}/totalpower`;

  // eslint-disable-next-line camelcase
  const t_formdata = {
    condiniton: {
      measures,
    },
  };
  if (selectedsantral !== 'null') {
    t_formdata.condiniton.santral = selectedsantral;
  }
  const totalData = request.Api(url3, t_formdata, 'post');
  promise.push(totalData);

  Promise.all(promise).then((values) => {
    const tmpData = [];

    if (values[0].data.success === 'true') {
      let tmp;

      if (Array.isArray(values[0].data.data)) {
        tmp = values[0].data.data[0];
      } else {
        tmp = values[0].data.data;
      }

      // if (common.isArray(tmp)) {
      const obj = setMeasures(tmp);
      obj.date = moment().format('DD-MM-YYYY');
      tmpData.push(obj);

      // }
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });
      obj.date = moment().format('DD-MM-YYYY');
      tmpData.push(obj);
    }

    if (values[1].data.success === 'true') {
      let tmp;

      if (Array.isArray(values[1].data.data)) {
        if (values[1].data.data.length > 0) {
          tmp = values[1].data.data[0];
        }
      } else {
        tmp = values[1].data.data;
      }

      // eslint-disable-next-line radix
      const m = parseInt(moment().format('MM'));
      // if (common.isArray(tmp)) {
      if (!helper.is_Empty(tmp)) {
        const obj = setMeasures(tmp);
        obj.date = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;
        tmpData.push(obj);
      }

      // }
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });
      // eslint-disable-next-line radix
      const m = parseInt(moment().format('MM'));

      obj.date = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;
      tmpData.push(obj);
    }

    if (values[2].data.success === 'true') {
      let tmp;
      if (Array.isArray(values[2].data.data)) {
        tmp = values[2].data.data[0];
      } else {
        tmp = values[2].data.data;
      }

      const obj = setMeasures(tmp);
      obj.date = moment().format('YYYY');
      tmpData.push(obj);
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });

      obj.date = moment().format('YYYY');
      tmpData.push(obj);
    }
    if (values[3].data.success === 'true') {
      let tmp;
      if (Array.isArray(values[3].data.data)) {
        tmp = values[3].data.data[0];
      } else {
        tmp = values[3].data.data;
      }

      const obj = {
        date: 'Toplam',
        DailyEnergy: (parseFloat(tmp.TotalEnergy) / 1000).toFixed(2),
        PerformanceRatio: '',
        TheoreticalPower: '',
        // Price: (parseFloat(tmp.TotalEnergy) * 0.133 * 1.18).toFixed(0),
      };

      tmpData.push(obj);
    }

    resolve(tmpData);
  });
});
const setMeasures = (data) => {
  const temp = {};

  Object.keys(data).forEach((measure) => {
    if (measure === 'BarrelsofOil') {
      temp[measure] = (parseFloat(data[measure])).toFixed(0) || 0;
    }
    if (measure === 'CO2Avoided') {
      temp[measure] = (parseFloat(data[measure])).toFixed(0) || 0;
    }
    if (measure === 'EquivalentTrees') {
      temp[measure] = (parseFloat(data[measure])).toFixed(0) || 0;
    }

    if (measure === 'PerformanceRatio') {
      temp[measure] = (parseFloat(data[measure])).toFixed(0) || 0;
    }
    if (measure === 'TheoreticalPower') {
      // eslint-disable-next-line no-param-reassign
      temp[measure] = (parseFloat(data[measure] / 1000)).toFixed(2) || 0;
    }

    if (measure === 'DailyEnergy') {
      const daily = parseFloat(data[measure]) || 0;
      temp[measure] = (parseFloat(daily / 1000)).toFixed(2) || 0;
      // data.Price = (parseFloat(daily) / 1000 * 0.133 * 1.18 * 1000).toFixed(0);
    }
  });

  return temp;
};

// eslint-disable-next-line camelcase
const getSantralMinuteData_Dash = (formdata, measureList, text, self) => new Promise((resolve) => {
  getSantrallerMinuteGrup(formdata).then((_rawData) => {
    if (_rawData.status !== 200) {
      resolve({});
      return;
    }
    if (_rawData.data.success === 'false') {
      resolve({});
      return;
    }
    const sortType = 'Hour';

    const colors = ['#5470C6', '#91CC75', '#EE6666'];
    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const series = [];
    const measures = [];
    const timeField = [];
    const yAxis = [];
    const max_val = {};

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      tempTimeData[date] = item;

      measureList.forEach((measure) => {
        const tmp1 = parseInt(item[measure.value]);

        if (!max_val[measure.value]) {
          max_val[measure.value] = 0;
        }

        if (tmp1 > max_val[measure.value]) {
          max_val[measure.value] = tmp1;
        }
      });
    });

    let colorKey = 0;
    let offset = 0;
    let total_Val = 0;

    const {
      selectedFarm,
    } = self.$store.getters;

    const step_time = helper.getFarmStep(selectedFarm.prefix);

    measureList.forEach((measure) => {
      measures.push(`${measure.value} ${measure.unit}`);
      const tempData = [];
      const minute = 0;
      const hour = 5;
      const vals = 0;

      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += step_time) {
          let h;
          let
            m;
          i < 10 ? h = `0${i}` : h = `${i}`;
          x < 10 ? m = `0${x}` : m = `${x}`;

          const time = `${h}:${m}`;

          if (colorKey == 0) {
            timeField.push(time);
          }

          if (tempTimeData[time]) {
            tempData.push(Math.round(tempTimeData[time][measure.value], 1));
          } else {
            tempData.push(0);
          }
        }
      }

      const {
        chartTypeModel,
      } = self;
      let chartType = self.chartTypeModel;
      if (chartType === 'line2') {
        chartType = 'line';
      }

      const obj = {
        name: `${i18n.t(measure.value)}(${measure.unit})`,
        data: tempData,
        type: chartType,
      };

      let position = 'left';
      if (colorKey > 0) {
        position = 'right';
        obj.yAxisIndex = colorKey;
      }

      if (colorKey > 1) {
        offset += 110;
      }
      if (chartTypeModel == 'line2') {
        obj.areaStyle = {};
      }

      // eslint-disable-next-line camelcase
      total_Val = max_val[measure.value] * 120 / 100;

      const x_Axis = {
        type: 'value',
        name: `${i18n.t(measure.value)}(${measure.unit})`,
        min: 0,
        max: total_Val,
        offset,
        position,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[colorKey],
          },
        },
        axisLabel: {
          formatter: `{value} ${measure.unit}`,
        },
      };
      if (colorKey > 1) {
        x_Axis.show = false;
      }

      yAxis.push(x_Axis);
      series.push(obj);
      colorKey += 1;
    });

    const options = chartOptions.line_options_multies(text, measures, timeField, series,
      colors, yAxis);

    resolve(options);
  });
});

export function getSantralListLiveData(santralData, santrals, measureList) {
  return Promise.all([santralData, santrals]).then((values) => {
    const liveData = [];

    const alarm = {
      connection: 0,
      error: 0,
      warning: 0,
    };

    const kList = ['date', 'error', 'warning', 'connection'];
    Object.keys(santrals).forEach((key) => {
      const obj = {
        santral: santrals[key].santral,
        NominalPlantPower: santrals[key].NominalPlantPower.value,
        date: '',
        error: 0,
        warning: 0,
        connection: 0,
      };

      if (values[0].data.success === 'true') {
        const value = values[0].data.data[key];
        kList.forEach((k) => {
          if (!helper.is_Empty(value[k])) {
            obj[k] = value[k];
            alarm[k] += value[k];
          }
        });

        measureList.forEach((measure) => {
          // eslint-disable-next-line no-shadow
          let value = 0;

          const vals = values[0].data.data[key][measure];
          if (typeof vals === 'object' && vals !== undefined && vals !== null) {
            value = vals.value;
          } else {
            value = (parseFloat(vals)).toFixed(2);
          }

          obj[measure] = value;
        });
      }
      liveData.push(obj);
    });

    return {
      alarm,
      liveData,
    };
  });
}

export default {
  getSantralLiveData,
  get_SantralLiveData,
  getSantralTotalTableData,
  getSantralMinuteData_Dash,
  getSantralStatsData,
  getSantrallerStatsData,
};
