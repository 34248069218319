<template>
  <v-dialog v-model="dialog" width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text :color="alarmColor" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-bell</v-icon>
        <h4>{{ alarmCount }}</h4>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Hata ve Uyarılar
      </v-card-title>

      <v-card-text>
        <v-col cols="12" class="fullgridtable pl-0 pr-0 pb-1">
          <v-card>
            <v-data-table
              :id="santralDataTable"
              dense
              :items-per-page.sync="itemsPerPage"
              :server-items-length="itemsLength"
              :footer-props="{
                'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
                showFirstLastPage: true,
                itemsPerPageText: 'Limit',
              }"
              :headers="headersalarm"
              :page.sync="currentPage"
              :loading="loading"
              loading-text="Yükleniyor... Lütfen bekleyiniz"
              :items="dessertsalarm"
              class="elevation-1"
            />
          </v-card>
        </v-col>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false"> Kapat </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as Notification from "@/api/Notification/notification";
import moment from "moment";

export default {
  name: "NotifyModal",
  props: ["dialog", "prefix"],
  data: () => ({
    headersalarm: [],
    itemsPerPage: 5,
    itemsLength: 5,
    dessertsalarm: [],
    santralDataTable: "santralDataTable",
    alarmCount: 0,
    alarmColor: "grey",
    loading: false,
    production_header: [],
    currentPage: 1,
    // dialog: false,
  }),
  watch: {
    currentPage() {
      this.notificationControl();
    },
    itemsPerPage() {
      this.notificationControl();
    },
    prefix() {
      console.log(this.prefix);
      this.notificationControl();
    },
  },
  created() {},
  mounted() {
    this.notificationControl();
    const time = 300000;
    setInterval(this.notificationControl, time);
  },
  methods: {
    notificationControl() {
      this.finishDate = this.startDate;

      this.headersalarm = [
        { text: "Tip", value: "type" },
        { text: "Kod", value: "code" },
        { text: "Santral", value: "santral" },

        // { text: "channel", value: "channel" },
        { text: "Cihaz", value: "device" },
        { text: "Açıklama", value: "message" },
        { text: "Başlangıç Tarih", value: "createTime" },
        { text: "Mail Tarih", value: "mailSendDate" },

        { text: "Güncelleme Tarih", value: "updateTime" },
        { text: "Bitiş Tarih", value: "passivetime" },
      ];
      // moment().format("YYYY-MM-DD"),
      const rest = {
        condinition:{
          status: ["Active"],
          startDate: moment().format("YYYY-MM-DD"),
        },
        // finishDate: moment().format("YYYY-MM-DD"),
        skip: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      };

      if (this.prefix !== null && this.prefix !== undefined) {
        rest.prefix = this.prefix;
      }
      const self = this;

      const tempData1 = Notification.default.getError(rest);
      tempData1.then((res) => {
        self.alarmCount = res.count;

        self.dessertsalarm = res.data;
        self.itemsLength = res.count;

        self.loading = false;
        if (self.alarmCount > 0) {
          self.alarmColor = "red";
        }
      });
    },
  },
};
</script>
