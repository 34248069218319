<template>
  <div>
    <v-row class="mr-1 mt-1">
      <v-col
        v-for="(measure, key) in measureList"
        :key="key"
        cols="3"
        sm="3"
        md="3"
        class="pr-0 mt-0 pt-0 m-0"
      >
        <v-card>
          <div
            :id="measure.value + 'general-santral-live-data'"
            class-name="widget-content"
            style="width: 95%; height: 190px"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as Santral from "@/api/Dashboard/Santral";
import i18n from "@/locale";
import moment from "moment";
import * as echarts from "echarts";
import { getSantralLastpowerData } from "@/api/Request/santral";
import { getSensorData } from "@/api/Request/sensor";

export default {
  components: {},
  data: () => ({
    chartData: {},
  }),
  computed: {
    measureList() {
      return [
        {
          value: "PerformanceRatio",
          text: i18n.t("PerformanceRatio"),
          type: "float",
          unit: "%",
        },
        {
          value: "IstantaneousPower",
          text: i18n.t("IstantaneousPower"),
          type: "float",
          unit: "Kw",
        },
        { value: "Irr", text: i18n.t("Irradiation") },
        { value: "Temp", text: i18n.t("Temperature") },
      ];
    },
  },
  watch: {},
  created() {},
  mounted() {
    setTimeout(() => {
      this.getSantralLiveData();
    }, 1500);

    setInterval(this.getSantralLiveData, 300000);
  },

  methods: {
    async getSantralLiveData() {
      const formdata = {};

      const measures = [];
      const generalStatsMeasureList = [
        {
          value: "PerformanceRatio",
          text: i18n.t("PerformanceRatio"),
          type: "float",
          unit: "%",
        },
        {
          value: "IstantaneousPower",
          text: i18n.t("IstantaneousPower"),
          type: "float",
          unit: "Kw",
        },
      ];

      generalStatsMeasureList.forEach((item) => {
        measures.push(item.value);
      });
      // let date = "2021-08-06";
      const date = moment().format("YYYY-MM-DD");

      formdata.santral = {
        condiniton: {
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: "datetimestamp", sortOrder: "desc" }],
          count: "0",
          totalVal: "1",
          date,
          measures,
        },
      };

      const sensor = await this.$store.getters.santralSensorData;

      if (Object.keys(sensor).length > 0) {
        const sensorList = {};
        Object.keys(sensor).forEach((item) => {
          const sns = {};
          Object.keys(sensor[item]).forEach((i) => {
            sns[sensor[item][i].SENSOR] = sensor[item][i];
          });

          sensorList[item] = sns;
        });
        formdata.sensorbox = {
          condiniton: {
            skip: 0,
            limit: 1,
            orderBys: [{ fieldName: "datetimestamp", sortOrder: "desc" }],
            count: "0",
            totalVal: "1",
            date,
            sensor: sensorList,
          },
        };
      }
      formdata.sensorbox = {
        condiniton: {
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: "datetimestamp", sortOrder: "desc" }],
          count: "0",
          totalVal: "1",
          date,
        },
      };
      console.log(formdata);

      const santralData = getSantralLastpowerData(formdata.santral);
      const sensorboxData = getSensorData(formdata.sensorbox);
      const minuteData = Santral.default.get_SantralLiveData(
        santralData,
        sensorboxData,
        this.measureList,
      );

      const echartdom = {};
      const myChart = {};

      minuteData.then((res) => {
        const santrals = this.$store.getters.santralRawData;
        // console.log(res);
        if (santrals !== undefined) {
          // eslint-disable-next-line no-param-reassign
          res.IstantaneousPower.series[0].max = 1000 * santrals.length;
        }
        this.measureList.forEach((measure) => {
          const id = `${measure.value}general-santral-live-data`;
          echartdom[id] = document.getElementById(id);
          myChart[id] = echarts.init(echartdom[id]);
          myChart[id].setOption(res[measure.value]);
        });

        this.$store.dispatch("setGeneralUpdateTime", res.updateTime);
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
