<template>
  <v-col
    cols="12"
    sm="3"
    md="3"
    class="mt-0 pt-0 mb-0 pb-0"
  >
    <v-card
      v-if="componentKey"
      class="mt-0 pt-0 mb-0 pb-0"
      max-width="400"
    >
      <v-data-table
        dense
        :headers="santral_headers"
        :items="santral_items"
        hide-default-header
        hide-default-footer
        class="elevation-1"
      />
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    santral_items: [],
    componentKey: 1,
  }),
  computed: {
    santral_headers() {
      return [
        { text: 'type', value: 'type' },
        { text: 'value', value: 'value' },
      ];
    },
  },
  created() {
    this.componentKey += 1;
  },
  mounted() {
    const self = this;

    // eslint-disable-next-line no-use-before-define
    const myVarİnv = setInterval(myTimerİnv, 1000);
    let k = 0;
    function myTimerİnv() {
      // eslint-disable-next-line no-plusplus
      k++;
      if (k > 30) {
        clearInterval(myVarİnv);
      }
      const { santralInverterData } = self.$store.getters;

      let inverterQuantity = 0;
      if (Object.keys(santralInverterData).length > 0) {
        clearInterval(myVarİnv);

        Object.keys(santralInverterData).forEach((item) => {
          inverterQuantity += Object.keys(santralInverterData[item]).length;
        });

        self.santral_items = [
          {
            type: 'İnverter Sayısı',
            value: 0,
          },
          {
            type: 'Combiner Sayısı',
            value: 0,
          },
          {
            type: 'Strings Sayısı',
            value: 0,
          },
          {
            type: 'Sensor Sayısı',
            value: 0,
          },
          {
            type: 'Güncelleme zamanı',
            value: moment().format('YYYY-MM-DD HH:mm:s'),
          },
        ];

        self.santral_items[0].value = inverterQuantity;
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarString = setInterval(myTimerString, 1000);
    function myTimerString() {
      const { santralStringboxData } = self.$store.getters;
      let combinerQuantity = 0;
      let stringsQuantity = 0;
      if (Object.keys(santralStringboxData).length > 0) {
        clearInterval(myVarString);

        Object.keys(santralStringboxData).forEach((s) => {
          combinerQuantity += Object.keys(santralStringboxData[s]).length;
          Object.keys(santralStringboxData[s]).forEach((item) => {
            if ('stringQuantity' in santralStringboxData[s][item]) {
              // eslint-disable-next-line radix
              stringsQuantity += parseInt(santralStringboxData[s][item].stringQuantity);
            }
          });
        });
        self.santral_items[1].value = combinerQuantity;
        self.santral_items[2].value = stringsQuantity;
      }
    }

    // eslint-disable-next-line no-use-before-define
    const myVarSensor = setInterval(myTimerSensor, 1000);

    function myTimerSensor() {
      const { santralSensorData } = self.$store.getters;

      let sensorQuantity = 0;
      if (Object.keys(santralSensorData).length > 0) {
        clearInterval(myVarSensor);
        Object.keys(santralSensorData).forEach((item) => {
          sensorQuantity += Object.keys(santralSensorData[item]).length;
        });
        self.santral_items[3].value = sensorQuantity;
      }
    }

    setInterval(this.santralgeneralupdate, 600000);

    setTimeout(() => {
      self.santralgeneralupdate();
    }, 2000);
  },
  methods: {
    santralgeneralupdate() {
      if (this.santral_items.length > 0) {
        this.santral_items[4].value = this.$store.getters.santralgeneralupdate;
      }
    },
  },
};
</script>

<style scoped>
</style>
