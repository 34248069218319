var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":_vm.alarmColor,"dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell")]),_c('h4',[_vm._v(_vm._s(_vm.alarmCount))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Hata ve Uyarılar ")]),_c('v-card-text',[_c('v-col',{staticClass:"fullgridtable pl-0 pr-0 pb-1",attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":_vm.santralDataTable,"dense":"","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.itemsLength,"footer-props":{
              'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
              showFirstLastPage: true,
              itemsPerPageText: 'Limit',
            },"headers":_vm.headersalarm,"page":_vm.currentPage,"loading":_vm.loading,"loading-text":"Yükleniyor... Lütfen bekleyiniz","items":_vm.dessertsalarm},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.currentPage=$event}}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Kapat ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }